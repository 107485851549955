<div class="ibox float-e-margins">
    <div class="ibox-title">
        <h5>
            {{ 'Dashboard_Goto_Eshops' | translate }}
        </h5>
    </div>
    <div class="ibox-content" *ngIf="gotoEshops">
        <ng-container *ngFor="let item of gotoEshops.links">
            <div [ngClass]="item.isBeta ? 'beta' : 'standart'">
                <a [href]="gotoLink(item)" target="_blank" [attr.data-tid]="'btnEshop' + getTidUrl(item.url)">{{ item.text }}</a
                ><br />
                <ng-container *ngFor="let subItem of item.links">
                    <a
                        [href]="gotoLink(subItem)"
                        [ngClass]="subItem.isBeta ? 'beta' : 'standart'"
                        target="_blank"
                        [attr.data-tid]="'btnEshop' + getTidUrl(subItem.url)"
                        >{{ subItem.text }}</a
                    >
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
