import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'app/components/components.module';
import { DashboardDataService } from './dashboard-data.service';
import { DashboardFormService } from './dashboard-form.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GotoEshopsComponent } from './goto-eshop/goto-eshops.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent
    }
];

@NgModule({
    imports: [ComponentsModule, TranslateModule, ReactiveFormsModule, CommonModule, RouterModule.forChild(routes)],
    declarations: [DashboardComponent, GotoEshopsComponent],

    providers: [DashboardDataService, DashboardFormService]
})
export class DashboardModule {}
