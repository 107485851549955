import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'app/common/services/session.service';
import { IReleaseDefinitionDto, IReleaseDto } from 'app/models/azure-dev-ops';
import { IEshopLinksDto } from 'app/models/eshop';
import { Observable } from 'rxjs';
import { IZabbixDto } from './dto/zabbix-dto';
import { IZabbixItemDto } from './dto/zabbix-item-dto';

export type IReleaseDefinitionExtendedDto = IReleaseDefinitionDto & { latestRelease?: IReleaseDto };

@Injectable()
export class DashboardDataService {
    constructor(private http: HttpClient, public translate: TranslateService, private session: SessionService) {}

    public hasUserServerRights = this.session.user.hasPermission('ServerList_view');
    public hasUserMaintanaceRights = this.session.user.hasPermission('ZabbixMaintenance_edit');

    public getZabbix() {
        return this.http.get<IZabbixDto>(`/api/dashboard/`);
    }

    public setZabbix(dto: IZabbixItemDto) {
        return this.http.put(`/api/dashboard/`, dto);
    }

    public deleteZabbix(dto: IZabbixItemDto) {
        return this.http.post<any>(`/api/dashboard/delete-zabbix-mtn`, dto);
    }

    public getReleaseDefinitions(project: string) {
        return this.http.get<Array<IReleaseDefinitionExtendedDto>>('/api/dashboard/release-definitions', { params: { project: project } });
    }

    public getRelease(project: string, id: number) {
        return this.http.get<IReleaseDto>(`/api/dashboard/release-definitions/${id}`, { params: { project: project } });
    }

    public getGotoEshop(): Observable<IEshopLinksDto> {
        return this.http.get<IEshopLinksDto>(`/api/eshop/links`);
    }
}
