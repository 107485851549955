import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentType } from 'app/common/enums';
import { SessionService } from 'app/common/services';
import { IEshopLinkDto, IEshopLinksDto } from 'app/models/eshop';
import { DashboardDataService } from '../dashboard-data.service';

@Component({
    selector: 'app-goto-eshops',
    templateUrl: './goto-eshops.component.html',
    styleUrls: ['./goto-eshops.component.scss']
})
export class GotoEshopsComponent implements OnInit {
    public gotoEshops: IEshopLinksDto;
    constructor(public dashboardDataService: DashboardDataService, public translate: TranslateService, private session: SessionService) {}

    ngOnInit() {
        this.dashboardDataService.getGotoEshop().subscribe((res) => {
            this.gotoEshops = res;
        });
    }

    public gotoLink(item: IEshopLinkDto) {
        if (this.session.user.environment === EnvironmentType.Production && !item.isBeta) {
            return item.url;
        }
        return `${item.url}/services/admlogin.ashx?id=${this.gotoEshops.userId}&code=${this.gotoEshops.userCode}`;
    }

    public getTidUrl(url: string) {
        return url.replace(/(http:\/\/|https:\/\/)/g, '');
    }
}
