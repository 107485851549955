<app-page-header [title]="'Dashboard_HomePage' | translate"> </app-page-header>
<div class="wrapper wrapper-content animated fadeInRight">
    <div class="ibox">
        <div class="row flexrow">
            <div class="col-xs-12 col-md-6 col-xl-4">
                <div class="ibox float-e-margins">
                    <div class="ibox-content">
                        <div style="overflow: hidden">
                            <div class="row">
                                <div [ngClass]="{ 'disabled-block': !hasWebSettingRight }">
                                    <div class="col-xs-6 form-group">
                                        <a routerLink="/Utils/WebSetting" data-tid="btnWebSetting"> {{ 'Menu_Utility_WebSetting' | translate }}</a>
                                    </div>
                                    <div class="col-xs-6 text-right form-group">
                                        <a routerLink="/Utils/WebSetting/380" data-tid="btnWebSetting380" class="btn btn-primary btn-xs">
                                            <i class="fas fa-pencil-alt"></i>
                                            {{ 'Dashboard_UpdateStockInOrderSend' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <app-goto-eshops *ngIf="canShowGotoEshops()" class="col-xs-12 col-md-6 col-xl-12"> </app-goto-eshops>
        </div>
    </div>
</div>
<app-page-footer></app-page-footer>
