import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from 'app/common/services';
import { Subject } from 'rxjs';
import { DashboardDataService, IReleaseDefinitionExtendedDto } from '../dashboard-data.service';
import { IVstsEnvironmentsDto } from '../dto/vsts-dto';

interface IProject {
    name: 'admin' | 'alzaWeb' | 'affiliate';
    key: string;
    showAll: boolean;
    definitions: Array<IReleaseDefinitionExtendedDto>;
    activeDefinitions: Array<IReleaseDefinitionExtendedDto>;
}

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    public projects: Array<IProject> = [
        { name: 'admin', key: 'admin', showAll: false, definitions: [], activeDefinitions: [] },
        { name: 'alzaWeb', key: 'alzaWeb', showAll: false, definitions: [], activeDefinitions: [] },
        { name: 'affiliate', key: 'affiliate2.0', showAll: false, definitions: [], activeDefinitions: [] }
    ];
    public vsts: IVstsEnvironmentsDto = { admin: [], alzaWeb: [], affiliate: [] };
    public vstsActiveItems: IVstsEnvironmentsDto = { admin: [], alzaWeb: [], affiliate: [] };
    public date: Date;
    public projectName: string;
    public hasSettingsViewRight: boolean;
    public hasWebNoteViewRight: boolean;
    public hasWebSettingRight: boolean;

    private _unsubscribe = new Subject<void>();

    constructor(public dashboardDataService: DashboardDataService, private readonly http: HttpClient, private readonly sessionService: SessionService) {}

    ngOnInit() {
        this.hasSettingsViewRight = this.sessionService.user.hasPermission('Settings_view');
        this.hasWebSettingRight = this.sessionService.user.hasPermission('WebSetting');
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    public toggleAll(ev: Event, environment: string) {
        ev.stopPropagation();
        this.projects.find((p) => p.name === environment).showAll = !this.projects.find((p) => p.name === environment).showAll;
    }

    public canShowGotoEshops() {
        return !this.sessionService.user.isExterne;
    }
}
