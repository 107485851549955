import { Validators } from '@alza/cms-components';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormHelper } from 'app/components/forms/form-helper.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardFormService {
    public form: UntypedFormGroup;

    constructor(private formHelper: FormHelper) {}

    initUserForm() {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(),
            name: new UntypedFormControl(),
            activeFrom: new UntypedFormControl(null, [Validators.required]),
            activeTo: new UntypedFormControl(null, [Validators.required])
        });
        return this.form;
    }

    getFormId() {
        return this.form.get('id').value as number;
    }

    getFormGroup() {
        if (this.form) {
            return this.form;
        }
        return null;
    }

    getForm() {
        return this.form.get('form') as UntypedFormArray;
    }

    public setZabbix(data: UntypedFormGroup) {
        const control = new UntypedFormGroup({
            id: new UntypedFormControl(),
            name: new UntypedFormControl(),
            datefrom: new UntypedFormControl(null, [Validators.required]),
            dateTo: new UntypedFormControl(null, [Validators.required])
        });
        if (data) {
            control.patchValue(data);
        }
    }
}
